import React, { useRef, useEffect, useMemo } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { LightningStorm, LightningStrike } from "three-stdlib";
import _ from "lodash";
import { jstr } from "../../utils/utils";

extend({ EffectComposer, RenderPass, OutlinePass });

const base = {
  color_hue: 1,
  color_sat: 0.9,
  color_trans: 0.3,
};

const presets = {
  red: {
    color_hue: 0.332,
    color_sat: 0.95,
    color_trans: 0.43,
    override_base: 0xdd0062,
  },
  green: {
    color_hue: 125,
  },
  cyan: {
    color_hue: 180,
  },
  yellow: {
    color_hue: 60,
  },
  rainbow: {
    rainspeed: 40,
  },
};

const gen_preset_config = (preset, override_config) => {
  let o = _.cloneDeep(base);
  if (preset) {
    let pr = presets[preset] || {};
    o = { ...o, ...pr };
  }
  o.preset = preset;
  if (override_config) o = { ...o, ...override_config };
  return o;
};

export const ElectricTrail = ({ preset = null, override_config = null }) => {
  const ref = useRef();

  const o = useMemo(() => {
    let o = gen_preset_config(preset, override_config);
    return o;
  }, [preset, jstr(override_config)]);
  const ct = o;

  const { camera, scene, gl: renderer } = useThree();

  const composer_ref = useRef();
  const renderPass_ref = useRef();
  const outlinePass_ref = useRef();

  const lightning_material_ref = useRef();
  useEffect(() => {
    const initialColor = new THREE.Color().setHSL(
      ct.color_hue / 360,
      ct.color_sat,
      ct.color_trans,
    );
    lightning_material_ref.current = new THREE.MeshStandardMaterial({
      color: initialColor,
      emissive: initialColor,
      emissiveIntensity: 10,
    });
  }, []);
  useEffect(() => {
    if (lightning_material_ref.current) {
      const newColor = new THREE.Color().setHSL(
        ct.color_hue / 360,
        ct.color_sat,
        ct.color_trans,
      );
      if (ct.override_base)
        lightning_material_ref.current.color.set(ct.override_base);
      else lightning_material_ref.current.color.set(newColor);
      if (lightning_material_ref.current.emissive)
        lightning_material_ref.current.emissive.set(newColor);
      // console.log("update lightning_material_ref");
    }
  }, [jstr(ct)]);

  function createOutline(scene, objectsArray) {
    const composer = composer_ref.current;
    const outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      scene,
      camera,
      objectsArray,
    );
    outlinePass.edgeStrength = 2;
    outlinePass.edgeGlow = 2.5;
    outlinePass.edgeThickness = 1;
    outlinePass.visibleEdgeColor.set(0x00aaff);
    composer.addPass(outlinePass);
    return outlinePass;
  }
  useEffect(() => {
    // console.log("Effect runs once");

    const composer = new EffectComposer(renderer);
    composer_ref.current = composer;

    const renderPass = new RenderPass(scene, camera);
    renderPass_ref.current = renderPass;
    composer.addPass(renderPass);

    const handleResize = () => {
      composer_ref.current.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      console.log("cleanup on unmount");
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const create_cube = () => {
    const cube = new THREE.Mesh(
      new THREE.BoxGeometry(2, 1, 1),
      new THREE.MeshBasicMaterial({
        color: 0x0000ff,
      }),
    );
    cube.scale.set(0.5, 0.5, 0.5);
    cube.position.set(0, 0, 0);

    ref.current.add(cube); // Add to group, not to scene
  };

  const lightnings_n = 3;
  const src_ref = useRef();
  const dst_refs = useRef([]);
  const lightnings_ref = useRef([]);

  const r = 0.5;
  const h = 4;
  const dst_pos_s = [
    [h, r, 0],
    [h, 0, r / 2],
    [h, 0, -r / 2],
  ];

  const create_rays = () => {
    const outlineMeshArray = [];

    const rayParams1 = {
      sourceOffset: new THREE.Vector3(),
      destOffset: new THREE.Vector3(),
      radius0: 0.015,
      radius1: 0.005,
      minRadius: 2.5,
      maxIterations: 7,
      isEternal: true,

      timeScale: 0.7,

      propagationTimeFactor: 0.05,
      vanishingTimeFactor: 0.95,
      subrayPeriod: 2.5,
      subrayDutyCycle: 0.3,
      maxSubrayRecursion: 3,
      ramification: 7,
      recursionProbability: 0.6,

      roughness: 0.85,
      straightness: 0.68,
    };

    for (let i = 0; i < lightnings_n; i++) {
      let lightningStrike = new LightningStrike(rayParams1);
      let lightningStrikeMesh = new THREE.Mesh(
        lightningStrike,
        lightning_material_ref.current,
      );
      lightnings_ref.current.push(lightningStrike);
      ref.current.add(lightningStrikeMesh);
      outlineMeshArray.push(lightningStrikeMesh);
    }

    // create_cube();

    // createOutline(scene, outlineMeshArray);
  };

  useEffect(() => {
    create_rays();
  }, []);

  const tref = useRef(0);
  useFrame(() => {
    let t = tref.current + 0.01;
    tref.current = t;
    let src = src_ref.current;
    for (let i = 0; i < lightnings_n; i++) {
      let dst = dst_refs.current[i];
      let l = lightnings_ref.current[i];
      if (l) {
        l.rayParameters.sourceOffset.copy(src.position);
        l.rayParameters.destOffset.copy(dst.position);
        l.update(t);
      }
    }

    let angle = t;
    dst_refs.current.forEach((dst, i) => {
      if (!dst) return;
      let o = dst_pos_s[i];
      let original = { x: o[0], y: o[1], z: o[2] };
      const radius = Math.sqrt(original.y ** 2 + original.z ** 2);
      const baseAngle = Math.atan2(original.z, original.y);

      dst.position.y = radius * Math.cos(angle + baseAngle);
      dst.position.z = radius * Math.sin(angle + baseAngle);
    });

    if (preset == "rainbow") {
      let hue = parseInt((t * ct.rainspeed) % 360) / 360;
      let c = new THREE.Color().setHSL(hue, base.color_sat, 0.5);
      lightning_material_ref.current.color.set(c);
      if (lightning_material_ref.current.emissive)
        lightning_material_ref.current.emissive.set(c);
    }
  });

  // useEffect(() => {
  //   create_rays();
  // }, [ref.current]);

  useFrame(() => {
    if (composer_ref.current) {
      composer_ref.current.render();
    }
  });

  return (
    <group ref={ref} position={[0.78, 0.4, 0]} scale={1}>
      <group ref={src_ref} position={[-0.2, 0, 0]}>
        <mesh scale={0.05}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial
            transparent={true}
            opacity={0}
            color={"#ff0000"}
          />
        </mesh>
      </group>

      {dst_pos_s.map((p, i) => {
        return (
          <group
            key={i}
            position={p}
            ref={(ea) => {
              dst_refs.current[i] = ea;
            }}
          >
            <mesh scale={0.05}>
              <boxGeometry args={[1, 1, 1]} />
              <meshStandardMaterial
                transparent={true}
                opacity={0}
                color={"#00ff00"}
              />
            </mesh>
          </group>
        );
      })}
    </group>
  );
};

export default ElectricTrail;
