import { useFrame, useLoader } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { BufferAttribute, MeshBasicMaterial, PointsMaterial } from "three";
import { Trails_ResourceMap } from "../Trails_resourcemap";

const fireTextureFile = Trails_ResourceMap.fire;

const _VS = `
uniform float pointMultiplier;

attribute float size;
attribute float angle;
attribute vec4 colour;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;

  vAngle = vec2(cos(angle), sin(angle));
  vColour = colour;
}`;

const _FS = `

uniform sampler2D diffuseTexture;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
  gl_FragColor = texture2D(diffuseTexture, coords) * vColour;
}`;

export const FireTrail = () => {
  const ref = useRef();
  const [particles, setParticles] = useState([]);

  const pps = 30;
  const basevelocity = new THREE.Vector3(70, 0, 0);
  const init_size = 2;

  const fireTexture = useLoader(TextureLoader, fireTextureFile);
  const material = useMemo(() => {
    let uniforms = {
      diffuseTexture: {
        value: fireTexture,
      },
      pointMultiplier: {
        value:
          window.innerHeight / (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
      },
    };
    let m = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _VS,
      fragmentShader: _FS,
      blending: THREE.AdditiveBlending,
      depthTest: true,
      depthWrite: false,
      transparent: true,
      vertexColors: true,
    });
    return m;
  }, [fireTexture]);

  const geometry = useMemo(() => new THREE.BufferGeometry(), []);

  const addParticles = (timeElapsed) => {
    if (!ref.current.gdfsghk) {
      ref.current.gdfsghk = 0.0;
    }
    ref.current.gdfsghk += timeElapsed;
    const n = Math.floor(ref.current.gdfsghk * pps);
    ref.current.gdfsghk -= n / pps;

    const newParticles = [];
    for (let i = 0; i < n; i++) {
      const life = (Math.random() * 0.75 + 0.25) * 10.0;
      newParticles.push({
        position: new THREE.Vector3(
          (Math.random() * 2 - 1) * 1,
          (Math.random() * 2 - 1) * 0.5,
          (Math.random() * 2 - 1) * 0.5,
        ),
        size: (Math.random() * 0.5 + 0.5) * init_size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: Math.random() * 2.0 * Math.PI,
        velocity: basevelocity,
      });
    }
    setParticles((prev) => [...prev, ...newParticles]);
  };

  const updateGeometry = () => {
    const positions = [];
    const sizes = [];
    const colours = [];
    const angles = [];

    particles.forEach((p) => {
      positions.push(p.position.x, p.position.y, p.position.z);
      colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
      sizes.push(p.size);
      angles.push(p.rotation);
    });

    geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(positions), 3),
    );
    geometry.setAttribute(
      "size",
      new BufferAttribute(new Float32Array(sizes), 1),
    );
    geometry.setAttribute(
      "colour",
      new BufferAttribute(new Float32Array(colours), 4),
    );
    geometry.setAttribute(
      "angle",
      new BufferAttribute(new Float32Array(angles), 1),
    );

    geometry.attributes.position.needsUpdate = true;
    geometry.attributes.size.needsUpdate = true;
    geometry.attributes.colour.needsUpdate = true;
    geometry.attributes.angle.needsUpdate = true;
  };

  const updateParticles = (timeElapsed) => {
    setParticles((prev) => {
      return prev
        .map((p) => {
          p.life -= timeElapsed;
          const t = 1.0 - p.life / p.maxLife;
          p.rotation += timeElapsed * 0.5;
          p.alpha = 1 - t;
          p.size = p.size * (1 - t);
          p.colour = new THREE.Color().setHSL(t, 1, 0.6);
          p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));
          const drag = p.velocity.clone().multiplyScalar(timeElapsed * 0.3);
          p.velocity.sub(drag);
          return p;
        })
        .filter((p) => p.life > 0);
    });
  };

  const step = (timeElapsed) => {
    addParticles(timeElapsed);
    updateParticles(timeElapsed);
    updateGeometry();
  };

  useFrame((state, delta) => {
    step(delta);
  });

  return (
    <>
      <group ref={ref} position-x={0.78} position-y={0.3} scale={0.1}>
        <points material={material} geometry={geometry}></points>
      </group>
    </>
  );
};
export default FireTrail;
