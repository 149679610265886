import { useFrame, useLoader } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { BufferAttribute, MeshBasicMaterial, PointsMaterial } from "three";
import { useControls as useLevaControls } from "leva";
import _ from "lodash";
import { jstr } from "../../utils/utils";
import { Trails_ResourceMap } from "../Trails_resourcemap";

const base = {
  particles: 20,
  size_start: 0.6,
  size_reduction: 1.2,
  color_hue: 270,
  color_sat: 0.8,
  color_trans: 0.55,
  velocity_x: 40,
  boxsize: 1,
  scale_x: 0.05,
  scale_y: 0.008,
  scale_z: 0.008,
};
const presets = {
  red: {
    color_hue: 0,
  },
  green: {
    color_hue: 125,
  },
  cyan: {
    color_hue: 180,
  },
  yellow: {
    color_hue: 60,
  },
  rainbow1: {
    rainspeed: 40,
  },
  rainbow2: {
    rainspeed: 100,
  },
};

const _VS = `
uniform float pointMultiplier;

attribute float size;
attribute float angle;
attribute vec4 colour;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;

  vAngle = vec2(cos(angle), sin(angle));
  vColour = colour;
}`;

const _FS = `

uniform sampler2D diffuseTexture;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
  gl_FragColor = texture2D(diffuseTexture, coords) * vColour;
}`;

const gen_preset_config = (preset, override_config) => {
  let o = _.cloneDeep(base);
  if (preset) {
    let pr = presets[preset] || {};
    o = { ...o, ...pr };
  }
  o.preset = preset;
  if (override_config) o = { ...o, ...override_config };
  return o;
};

const get_init_color = (delta, elap, p, pr = {}) => {
  let { preset } = pr;
  if (preset == "rainbow1") {
    let hue = parseInt((elap * pr.rainspeed) % 360) / 360;
    return new THREE.Color().setHSL(hue, pr.color_sat, pr.color_trans);
  }
  let hue = pr.color_hue / 360;
  return new THREE.Color().setHSL(hue, pr.color_sat, pr.color_trans);
};

const get_update_color = (delta, elap, p, pr = {}) => {
  let { preset } = pr;
  if (preset == "rainbow2") {
    let hue = parseInt((elap * pr.rainspeed) % 360) / 360;
    return new THREE.Color().setHSL(hue, base.color_sat, base.color_trans);
  }
  return p.colour;
};

export const CrystalTrail = ({ preset = null, override_config = null }) => {
  const ref = useRef();
  const [particles, setParticles] = useState([]);

  const o = useMemo(() => {
    let o = gen_preset_config(preset, override_config);
    return o;
  }, [preset, jstr(override_config)]);
  const ct = o;

  // const ct = useLevaControls("crystal", {
  //   particles: {
  //     value: o.particles,
  //   },
  //   velocity_x: {
  //     value: o.velocity_x,
  //   },
  //   size_start: {
  //     value: o.size_start,
  //   },
  //   size_reduction: {
  //     value: o.size_reduction,
  //   },
  //   color_hue: {
  //     value: o.color_hue,
  //     min: 0,
  //     max: 360,
  //   },
  //   color_sat: {
  //     value: o.color_sat,
  //     min: 0,
  //     max: 1,
  //   },
  //   color_trans: {
  //     value: o.color_trans,
  //     min: 0,
  //     max: 1,
  //   },
  //   scale_x: {
  //     value: o.scale_x,
  //     min: 0,
  //     max: 1,
  //   },
  //   scale_y: {
  //     value: o.scale_y,
  //     min: 0,
  //     max: 1,
  //   },
  //   scale_z: {
  //     value: o.scale_z,
  //     min: 0,
  //     max: 1,
  //   },
  // });

  const pps = ct.particles;
  const basevelocity = new THREE.Vector3(ct.velocity_x, 0, 0);
  const boxsize_init = 1;
  const init_size = ct.size_start;
  const mx_spreadfac = 1;
  // 311, 83%, 50%
  const base_color = new THREE.Color().setHSL(
    ct.color_hue / 360,
    ct.color_sat,
    ct.color_trans,
  );
  const sizeReductionFactor = ct.size_reduction;
  const centerPullFactor = 0.001;
  // const texturefile = "/resources/glass_shards_3.png";
  const texturefile = Trails_ResourceMap.glass_shards_3;

  const trail_texture = useLoader(TextureLoader, texturefile);
  const material = useMemo(() => {
    let uniforms = {
      diffuseTexture: {
        value: trail_texture,
      },
      pointMultiplier: {
        value:
          window.innerHeight / (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
      },
    };
    let m = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _VS,
      fragmentShader: _FS,
      blending: THREE.AdditiveBlending,
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexColors: true,
    });
    return m;
  }, [trail_texture]);

  const geometry = useMemo(() => new THREE.BufferGeometry(), []);

  const addParticles = (delta, elapsed) => {
    if (!ref.current.gdfsghk) {
      ref.current.gdfsghk = 0.0;
    }
    ref.current.gdfsghk += delta;
    const n = Math.floor(ref.current.gdfsghk * pps);
    ref.current.gdfsghk -= n / pps;

    const newParticles = [];
    for (let i = 0; i < n; i++) {
      const life = (Math.random() * 0.75 + 0.25) * 10.0;
      let p = {
        position: new THREE.Vector3(
          (Math.random() * 2 - 1) * boxsize_init,
          (Math.random() * 2 - 1) * boxsize_init,
          (Math.random() * 2 - 1) * boxsize_init,
        ),
        size: init_size,
        colour: null,
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: Math.random() * 2.0 * Math.PI,
        velocity: basevelocity,
      };
      p.colour = get_init_color(delta, elapsed, p, ct);
      newParticles.push(p);
    }
    setParticles((prev) => [...prev, ...newParticles]);
  };

  const updateGeometry = () => {
    const positions = [];
    const sizes = [];
    const colours = [];
    const angles = [];

    particles.forEach((p) => {
      positions.push(p.position.x, p.position.y, p.position.z);
      colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
      sizes.push(p.size);
      angles.push(p.rotation);
    });

    geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(positions), 3),
    );
    geometry.setAttribute(
      "size",
      new BufferAttribute(new Float32Array(sizes), 1),
    );
    geometry.setAttribute(
      "colour",
      new BufferAttribute(new Float32Array(colours), 4),
    );
    geometry.setAttribute(
      "angle",
      new BufferAttribute(new Float32Array(angles), 1),
    );

    geometry.attributes.position.needsUpdate = true;
    geometry.attributes.size.needsUpdate = true;
    geometry.attributes.colour.needsUpdate = true;
    geometry.attributes.angle.needsUpdate = true;
  };

  const updateParticles = (delta_time, elapsed) => {
    setParticles((prev) => {
      return prev
        .map((p) => {
          p.life -= delta_time;
          const t = 1.0 - p.life / p.maxLife;
          p.rotation += delta_time * 0.5;
          p.alpha = 1 - t;
          let hue = parseInt((elapsed * 300) % 360) / 360;

          p.colour = get_update_color(delta_time, elapsed, p, ct);

          // Reduce size over time to bring particles to the center
          p.size *= 1 - sizeReductionFactor * delta_time;

          // Adjust position to converge towards the center
          const spreadFactor = p.alpha * mx_spreadfac; // Increase to make the cone wider
          const angle = Math.random() * Math.PI * 2;
          p.position.add(p.velocity.clone().multiplyScalar(delta_time));

          // Apply a force to pull particles towards the center
          const pullToCenter = new THREE.Vector3()
            .sub(p.position)
            .multiplyScalar(centerPullFactor * delta_time);
          pullToCenter.x = 0;
          p.position.add(pullToCenter);

          const drag = p.velocity.clone().multiplyScalar(delta_time * 0.3);
          p.velocity.sub(drag);
          return p;
        })
        .filter((p) => p.life > 0);
    });
  };

  const step = (delta_time, elapsed) => {
    addParticles(delta_time, elapsed);
    updateParticles(delta_time, elapsed);
    updateGeometry();
  };

  useFrame((state, delta) => {
    let elapsed = state.clock.getElapsedTime();
    step(delta, elapsed);
  });

  return (
    <>
      <group
        ref={ref}
        position-x={0.78}
        position-y={0.3}
        scale={[ct.scale_x, ct.scale_y, ct.scale_z]}
      >
        <points material={material} geometry={geometry}></points>
      </group>
    </>
  );
};
export default CrystalTrail;
