import _ from "lodash";

const bikes_skinned_base =
  "https://cdn.dnaracing.run/fbike-3d-models/bikes-skinned/";

// Bull - Taurus-X ?
// Shark - Sharkbyte ?

export const bikes_skinned_mapob = _.chain([
  ["Taurus-X", "bull_v1.glb", "ArmatureAction"],
  ["Shark Byte", "shark_v1.glb", "Bite"],
])
  .map((e) => {
    let [skin, obname, animkey] = e;
    return {
      skin,
      key: `tron_bike_${_.snakeCase(skin).toLowerCase()}`,
      link: `${bikes_skinned_base}${obname}`,
      animkey,
    };
  })
  .keyBy("skin")
  .value();

export const bikes_skinned_skins = _.keys(bikes_skinned_mapob);
