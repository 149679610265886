import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRace } from "./Race.js";
import { useRaceRunContext, useRaceRunnerControls } from "./RaceRunner.js";
import {
  dec,
  getv,
  jstr,
  nils,
  pad,
  postxt,
  time_diff_txt,
} from "../utils/utils.js";
import _, { max } from "lodash";
import { twMerge } from "tailwind-merge";
import { BY_Star, ContactTag, Tag } from "./utilityComps.js";
import { useNowContext } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faToggleOff,
  faToggleOn,
  faVolumeHigh,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import { mute_audio, unmute_audio } from "./Audio.js";
import { create as createZustand } from "zustand";
import { BikeCard } from "./BikeComps.js";
import colors from "tailwindcss/colors.js";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const InterfaceContext = createContext();
export const useInterfaceContext = () => useContext(InterfaceContext);

export const useInterfaceControls = createZustand((set) => ({
  loaded: false,
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));

const RaceDets = () => {
  const racect = useRace();

  const { race } = racect;
  const r = race;

  if (nils(r)) return <></>;

  return (
    <>
      <div className="absolute w-[100vw] xs:top-[0.1rem] lg:top-0 xs;left-[1rem] lg:left[1rem]">
        <div className="resp-text--1 resp-p-1 font-digi">
          <p className="text-white resp-text-1 resp-my-1 italic">
            {r.race_name}
          </p>
          <div className="fr-sc resp-gap-2">
            <span className="text-acc0">CB{r.cb}00</span>
            <span className="text-white">Class-{r.class}</span>
            {r.format == "normal" ? (
              <span className="text-pink-300 resp-px-1">
                {_.upperCase(r.payout)}
              </span>
            ) : (
              <span className="border-acc0 text-acc0 resp-px-1">
                {_.upperCase(r.format)}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ShowGates = () => {
  const { now } = useNowContext();
  const racect = useRace();
  const raceruncon = useRaceRunContext();
  const racerunningct = useRaceRunnerControls();

  const { race, racerunhs, hsob, rtstatus, starob } = racect;
  const { t_ref, runmode } = raceruncon;
  const { t, hsrunninginfo } = racerunningct;
  const incon = useInterfaceContext();
  const { viewmode, showpos } = incon;

  const r = race;
  const hids = getv(race, "hids");

  const [timertxt, dsecfont] = useMemo(() => {
    // console.log({ rtstatus });
    if (nils(r)) return [null, null];
    let t = t_ref.current;
    if (nils(t) || t >= 0) return [null, false];
    let [ar, highnil] = time_diff_txt(0, -t * 1000);
    highnil = Math.min(highnil, 1);
    let txt = [];
    if (rtstatus == "open") {
      return ["Race is OPEN", false];
    }
    if (rtstatus == "cancelled") {
      return ["Race is cancelled", false];
    } else if (rtstatus == "scheduled") {
      txt = [];
      ar = ar.slice(highnil, ar.length - 1);
      for (let [a, b, c] of ar) txt.push(`${pad(a, 2)} ${c}`);
      txt = txt.join(" ");
      return [txt, false];
    } else {
      ar = ar.slice(ar.length - 3, ar.length - 1);
      txt = [];
      for (let [a, b] of ar) txt.push(`${pad(a, 2)}`);
      txt = txt.join(":");
      return [txt, true];
    }
    return [null, false];
  }, [t, now, r]);

  if (nils(r)) return <></>;

  return (
    <>
      {!nils(timertxt) && (
        <>
          <div className="absolute w-[100vw] top-[5rem]">
            <div className="fr-cc resp-text-1 gap-2 text-acc0">
              <span className="font-digi">Starts in</span>
              <span
                className={twMerge(
                  dsecfont ? " font-dseg font-bold" : "font-digi",
                )}
              >
                {timertxt}
              </span>
            </div>
          </div>
        </>
      )}
      {showpos && (
        <div className="absolute w-[100vw] bottom-[2rem]">
          <div
            className={twMerge(
              "grid resp-gap-1 mx-auto w-max p-2 border border-acc0/40 bg-acc0/10 rounded-md",
              "xs:grid-cols-2",
              r.rgate > 6 ? "lg:grid-cols-4" : "",
              r.rgate == 6 ? "lg:grid-cols-3" : "",
              r.rgate == 2 ? "lg:grid-cols-2" : "",
            )}
          >
            {hids.map((hid, i) => {
              let gate = i + 1;
              let h = hsob[hid];
              let star = starob[hid] ?? 0;
              return (
                <div
                  key={hid}
                  className="fr-sc min-w-[5rem] resp-gap-1 text-left"
                >
                  <div className="xs:hidden lg:block">
                    <Tag className={"font-thin resp-text--2"}>
                      Gate-
                      <span className="resp-text-1">{gate}</span>
                    </Tag>
                  </div>
                  {!nils(h) && (
                    <div
                      className="resp-p-1 resp-text--1 min-w-[2rem] lg:min-w-[5rem] fr-sc"
                      style={{ borderBottom: `2px solid #${h.hex_code}` }}
                    >
                      <span>{h.name}</span>
                      <span className="resp-text--2 px-1">{h.hid}</span>
                      <BY_Star star={starob[hid]} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const Leader = () => {
  const { now } = useNowContext();
  const racect = useRace();
  const raceruncon = useRaceRunContext();
  const racerunningct = useRaceRunnerControls();

  const { race, racerunhs, hsob, rtstatus, starob } = racect;
  const { t_ref, runmode } = raceruncon;
  const { t, hsrunninginfo_ob } = racerunningct;
  const incon = useInterfaceContext();
  const { viewmode, mintime, maxtime } = incon;

  const r = race;
  const hids = getv(race, "hids");

  const ordhids = useMemo(() => {
    if (t > maxtime)
      return _.chain(racerunhs)
        .values()
        .sortBy((h) => h.time)
        .map("hid")
        .value();

    let ordhids = _.chain(hids)
      .sortBy((hid) => {
        let hfin = racerunhs[hid];
        let hdets = hsob[hid];
        let hruninf = hsrunninginfo_ob[hid];

        if (t > maxtime) return hfin.pos;

        if (nils(hdets) || nils(hruninf) || nils(hfin)) return 0;

        let fintime = hfin.time;

        let sortval = 0;

        if (t > fintime) sortval = hfin.time - 1e5;
        else sortval = -hruninf?.covered;

        return sortval;
      })
      .value();
    return ordhids;
  }, [t, hsrunninginfo_ob, racerunhs, maxtime]);
  return (
    <>
      <div className="absolute w-max top-[15vh] left-[2rem] bg-dark border border-acc0 rounded-md bg-opacity-80 p-4 px-2">
        <div className="flex flex-col justify-start items-start gap-[5px] w-full">
          {ordhids.map((hid, i) => {
            let rank = i + 1;
            let hfin = racerunhs[hid];
            let hdets = hsob[hid];
            let hruninf = hsrunninginfo_ob[hid];
            return (
              <div
                key={hid}
                className={twMerge(
                  "flex flex-row justify-start items-start gap-1 text-[0.5rem] w-full",
                )}
              >
                <span className=" italic w-[1.5rem] text-[0.8rem] text-right ">
                  {rank}
                </span>
                <span className=" italic w-[1rem] text-start ">
                  {postxt(rank).slice(-2)}
                </span>
                <div
                  style={{
                    borderBottom: `2px solid #${hdets?.hex_code ?? "FFFFFF"}`,
                  }}
                  className="flex flex-row justify-start items-start gap-1 px-2 flex-nowrap text-left  text-[0.6rem] flex-1"
                >
                  {/* <span className="text-[.5rem] px-1">{hid}</span> */}
                  <span>{hdets.name}</span>
                  <BY_Star className={"text-[10px]"} star={starob[hid]} />
                </div>
                <span className="font-mono w-[4rem] text-right text-[0.7rem]">
                  {t > hfin.time ? (
                    <span className="text-green-300 italic font-bold">
                      {dec(hfin.time, 3)}
                    </span>
                  ) : (
                    <span className="text-blue-300 italic">
                      {dec(hruninf?.covered, 0)}
                    </span>
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const ShowLive = () => {
  const { now } = useNowContext();
  const racect = useRace();
  const raceruncon = useRaceRunContext();
  const racerunningct = useRaceRunnerControls();

  const { race, racerunhs, hsob, rtstatus } = racect;
  const { t_ref, runmode } = raceruncon;
  const { t, hsrunninginfo_ob } = racerunningct;
  const incon = useInterfaceContext();
  const { viewmode, mintime, maxtime } = incon;

  const r = race;
  const hids = getv(race, "hids");

  const timertxt = useMemo(() => {
    if (nils(t) || t < 0) return null;
    if (!["running", "stopped", "replay"].includes(runmode)) return;
    let [ar, highnil] = time_diff_txt(0, t * 1000);
    highnil = Math.min(highnil, 1);
    ar = ar.slice(ar.length - 3, ar.length);
    let txt = [];
    for (let [a, b] of ar) txt.push(`${pad(a, 2)}`);
    txt = txt.join(" : ");
    return txt;
  }, [t, now, runmode]);

  return (
    <>
      {!nils(timertxt) && (
        <>
          {runmode == "replay" ? (
            <div className="absolute w-[100vw] xs:top-[2rem] lg:top-[1.5rem] text-green-400">
              <div className="fr-cc resp-text-1 gap-2 font-bold">
                <span className="font-digi">{"REPLAY"}</span>
                <span className="font-dseg text-white">{timertxt}</span>
                <span className="font-dseg opacity-0">{"REPLAY"}</span>
              </div>
            </div>
          ) : (
            <div className="absolute w-[100vw]  xs:top-[2rem] lg:top-[1.5rem]">
              <div className="fr-cc resp-text-1 gap-2 font-bold">
                <span className="font-dseg">{timertxt}</span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const InterfaceSettings = () => {
  const incon = useInterfaceContext();
  const { mute, set_mute, showpos, set_showpos } = incon;

  useEffect(() => {
    if (mute) {
      mute_audio();
    } else {
      unmute_audio();
    }
  }, [mute]);

  return (
    <>
      <div className="absolute bottom-[1.2rem] right-[1.2rem] z-[20] pointer-events-auto">
        <div className="rounded-md resp-p-2">
          <div className="flex flex-row resp-gap-4">
            <Tag
              className="fr-cc gap-2 resp-text--2"
              onClick={() => {
                set_showpos(!showpos);
              }}
            >
              <FontAwesomeIcon
                fontSize={10}
                icon={showpos ? faToggleOn : faToggleOff}
              />
              <span>{"Show Positions"}</span>
            </Tag>

            <Tag
              className="fr-cc resp-gap-2 resp-text--2"
              onClick={() => {
                set_mute(!mute);
              }}
            >
              <FontAwesomeIcon
                fontSize={10}
                icon={mute ? faVolumeMute : faVolumeHigh}
              />
              <span>{mute ? "Audio Muted" : "Audio ON"}</span>
            </Tag>
          </div>
        </div>
      </div>
    </>
  );
};

export function InterfaceWrapper(props) {
  const { now } = useNowContext();
  const racect = useRace();
  const raceruncon = useRaceRunContext();
  const racerunningct = useRaceRunnerControls();

  const inconct = useInterfaceControls();

  const { race, racerunhs, rtstatus } = racect;
  const { runmode } = raceruncon;
  const { t, hsrunninginfo } = racerunningct;

  const [mintime, maxtime] = useMemo(() => {
    if (["live", "finished"].includes(rtstatus)) {
      let times = _.map(racerunhs, "time");
      return [_.min(times), _.max(times)];
    }
    return [null, null];
  }, [rtstatus, jstr(race), jstr(racerunhs)]);

  const viewmode = useMemo(() => {
    let v = null;
    if (t < 0 || nils(t)) v = "showgates";
    if (t >= 0) {
      if (t < maxtime + 6) v = "showlive";
      else v = "showfin";
    }
    return v;
  }, [t, mintime, maxtime, now]);

  const [mute, set_mute] = useState(localStorage.getItem("mute") == "true");
  useEffect(() => {
    localStorage.setItem("mute", mute);
  }, [mute]);
  const [showpos, set_showpos] = useState(true);

  const [showhstats, set_showhstats] = useState(null);
  useMemo(() => {
    inconct.update({ set_showhstats });
  }, []);

  const rct = useRace();
  const { hsob } = rct;

  const incon = {
    viewmode,
    mintime,
    maxtime,
    mute,
    set_mute,

    showpos,
    set_showpos,

    showhstats,
    set_showhstats,
  };

  return (
    <InterfaceContext.Provider value={incon}>
      {props.children}
    </InterfaceContext.Provider>
  );
}

export function InterfaceInner() {
  const incon = useInterfaceContext();
  const { viewmode, showpos, showhstats, set_showhstats } = incon;

  const rct = useRace();
  const { hsob, race } = rct;

  return (
    <>
      <div className="html-overlay">
        {/* <div className="absolute w-[100vw] top-[1rem]">
          <div className="fr-cc">{viewmode}</div>
        </div> */}

        <RaceDets />

        {viewmode == "showgates" && nils(showhstats) && <ShowGates />}
        {viewmode == "showlive" && <ShowLive />}

        {showpos && (
          <>{["showlive", "showfin"].includes(viewmode) && <Leader />}</>
        )}

        <InterfaceSettings />

        <div className="absolute w-[100vw] bottom-[2.5rem] z-[20] pointer-events-auto">
          {showhstats && !nils(hsob[showhstats]) ? (
            <BikeCard
              bike={hsob[showhstats]}
              close={() => {
                set_showhstats(null);
              }}
            />
          ) : (
            <>
              {race?.is_arcade == true && viewmode !== "showgates" && (
                <div className="fr-cc gap-1">
                  <ContactTag
                    link={"https://discord.gg/j4k3NdY6St"}
                    icon={faDiscord}
                    txt={<>Discord</>}
                  />
                  <ContactTag
                    link={"https://market.dnaracing.run"}
                    icon={faMapMarkedAlt}
                    txt={
                      <div className="fc-cc">
                        <span>DNA Market</span>
                        <span className="resp-text--2 text-acc0">
                          Buy A Core Now
                        </span>
                      </div>
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const Interface = () => {
  return (
    <InterfaceWrapper>
      <InterfaceInner />
    </InterfaceWrapper>
  );
};

export default Interface;
