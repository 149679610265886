import React, { useEffect, useMemo, useRef, useState } from "react";
import { base_remote, useModelStoreCt } from "./ModelsStore.js";
import { useRace } from "./Race.js";
import { dec, jstr, nils } from "../utils/utils.js";
import transp from "../assets/images/loadingtransparent.png";
import _ from "lodash";
import { Tag } from "./utilityComps.js";
import { twMerge } from "tailwind-merge";
import { useRaceRunContext } from "./RaceRunner.js";
import { Helmet } from "react-helmet-async";
import { useAppContext } from "../App.js";

const clearSiteData = async () => {
  // Clear localStorage
  localStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear IndexedDB (example for a database named 'myDatabase')
  const req = indexedDB.deleteDatabase("gltfCache");
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
  };

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

const Intro = ({ onEnd }) => {
  const intro_src = `${base_remote}/scifi-track/intro_lg.mp4`;
  const audio_src = `${base_remote}/scifi-track/opening_music.wav`;

  const audio_ref = useRef(null);
  const video_ref = useRef(null);

  const onEnded = () => {
    if (audio_ref.current) {
      audio_ref.current.pause();
      audio_ref.current.currentTime = 0;
    }
    onEnd();
  };
  const onStart = () => {
    if (audio_ref.current) {
      setTimeout(() => {
        audio_ref.current.play();
      }, 2.5 * 1e3);
    }
  };

  return (
    <div className="absolute z-[1000] w-full h-[100vh] bg-black top-0 left-0">
      <video
        ref={video_ref}
        autoPlay
        muted={true}
        onEnded={onEnded}
        onPlay={onStart}
        className="absolute h-full w-full object-cover bg-slate-800 z-[8] transition-all duration-1000"
        src={intro_src}
      />
      <audio
        ref={audio_ref}
        autoPlay={false}
        className="absolute h-full w-full object-cover bg-slate-800 z-[8] transition-all duration-1000"
        src={audio_src}
      />
    </div>
  );
};

function LoadingScreen() {
  const { psearch } = useAppContext();
  const rct = useRace();
  const rruncon = useRaceRunContext();
  const mct = useModelStoreCt();
  const prog = useMemo(() => {
    let a = rct.loaded == true ? 1 : 0;
    let b = mct.loadprogress_n ?? 0;
    let n = _.mean([a, b]);

    // console.log({ a, b, n });
    return n ?? 0;
  }, [rct.loaded, mct.loadprogress_n]);

  const [start, set_start] = useState(false);
  const [showintro, set_showintro] = useState(false);
  const [showbtn, set_showbtn] = useState(false);
  useEffect(() => {
    console.log("prog", { prog });
    if (prog >= 1 && showbtn == false) {
      // setTimeout(() => {
      set_showbtn(true);
      // }, 1500);
    }
  }, [prog]);

  const on_enter_click = () => {
    if (!showbtn) return;
    set_start(true);
    // set_showintro(true);
    rruncon.set_init(true);
  };

  useEffect(() => {
    // console.log("skipenter", psearch?.skipenter, prog, showbtn);
    if (showbtn && prog >= 1 && psearch?.skipenter === "true") {
      on_enter_click();
    }
  }, [psearch?.skipenter, prog, showbtn]);

  if (showintro)
    return (
      <Intro
        onEnd={() => {
          set_showintro(false);
          rruncon.set_init(true);
        }}
      />
    );

  if (start) return <></>;

  return (
    <div className="LoadingScreen absolute z-[1000] w-full h-[100vh] bg-black top-0 left-0">
      <Helmet>
        <title>{"Loading 3D... FBike || DNA Racing"}</title>
      </Helmet>

      <div className="fc-cc mt-[20vh]">
        <div className="relative w-[12rem] h-[12rem] overflow-hidden">
          <div className="absolute w-full h-full bg-slate-800 z-[8] transition-all duration-1000"></div>
          <div
            style={{
              marginTop: `${12 * (1 - prog)}rem`,
            }}
            className="absolute w-full h-full bg-acc0 z-[10] transition-all duration-1000"
          ></div>
          <div className="absolute img-obey-cont z-[20]">
            <img src={transp} alt="" />
          </div>
        </div>
        {!nils(rct?.race_err) ? (
          <>
            <div className="absolute top-[5rem] text-red-400 fc-cc w-full">
              <div className="text-[1.5rem]">Error Occured!!</div>
              <div className="text-[1.2rem]">{rct.race_err}</div>
            </div>
          </>
        ) : (
          <>
            {prog >= 1 && showbtn ? (
              <Tag
                onClick={on_enter_click}
                className={twMerge(
                  "animate-pulse cursor-pointer opacity-100",
                  "my-2 xs:px-4 md:px-4 text-white border border-white font-digi text-[0.8rem]",
                  "transition-all duration-500",
                )}
              >
                Enter 3D Experience
              </Tag>
            ) : prog < 0.8 ? (
              <p className="resp-text--1">Loading... may take a minute</p>
            ) : prog < 0.95 ? (
              <p className="resp-text--1">Hang in there...</p>
            ) : prog >= 0.95 ? (
              <p className="resp-text--1">Almost there...</p>
            ) : null}
          </>
        )}
        <div className="fr-sc w-[50rem] max-w-[95vw] mx-auto  absolute top-[1rem]">
          <Tag
            onClick={clearSiteData}
            className={twMerge(
              "border border-red-300 text-red-300",
              "my-2 xs:px-4 md:px-4 text-[0.6rem]",
            )}
          >
            clear cache
          </Tag>
        </div>
      </div>
    </div>
  );
}
export default LoadingScreen;
