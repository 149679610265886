import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.js";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Race, useRace } from "./components/Race.js";
import { KeyboardControls } from "@react-three/drei";
import { MouseControls } from "./components/MouseControls.js";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router";
import LoadingScreen from "./components/LoadingScreen.js";
import { RaceRunWrapper } from "./components/RaceRunner.js";
import Interface, {
  InterfaceInner,
  InterfaceWrapper,
} from "./components/Interface.js";
import Podium from "./components/Podium.js";
import { nils } from "./utils/utils.js";
import {
  ModelsStoreWrapper,
  useModelStoreCt,
  useModelsStore,
} from "./components/ModelsStore.js";
import NoColor from "./wrappers/NoColor.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);
  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };
  const [appdata, set_appdata] = useState({});
  const s_appdata = () => {};

  const racect = useRace();
  const modelsct = useModelStoreCt();

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const test = psearch.race_id == "test";

  const [loaded, set_loaded] = useState(false);
  useEffect(() => {
    set_loaded(true);
  }, []);

  const acon = {
    appdata,
    set_appdata,
    s_appdata,

    psearch,
    upd_psearch,
    history,
    location,
    test,
  };

  return (
    <div className="App font-digi">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={acon}>
          <HelmetProvider>
            <Helmet>
              <title>{"3D Player FBike || DNA Racing"}</title>
            </Helmet>
            <div className="main-page z-[0]"></div>
            <Race />
            <RaceRunWrapper>
              <LoadingScreen />

              <Canvas
                gl={{ antialias: false, stencil: false, pixelRatio: 1 }}
                camera={{ position: [2, 2, 0], fov: 80 }}
              >
                <ModelsStoreWrapper>
                  <Experience />
                </ModelsStoreWrapper>
                <MouseControls />
              </Canvas>
              <InterfaceWrapper>
                <InterfaceInner />
              </InterfaceWrapper>
            </RaceRunWrapper>
            <NoColor />
          </HelmetProvider>
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
